import { ComponentWithAs, Icon, IconProps } from "@chakra-ui/react"
import { DemographicFinancialProductsIcon } from "Icons/DemographicFinancialProductsIcon"
import { DemographicGeneralIcon } from "Icons/DemographicGeneralIcon"
import { DemographicHobbiesIcon } from "Icons/DemographicHobbiesIcon"
import { DemographicIndustryIcon } from "Icons/DemographicIndustryIcon"
import { DemographicShoppingIcon } from "Icons/DemographicShoppingIcon"
import { DemographicTechnologyIcon } from "Icons/DemographicTechnologyIcon"
import { reportErrorToSentry } from "Utilities/error"
import { camelCase } from "lodash"
import React, { useEffect } from "react"

type Props = {
  groupName: string
}

const ICONS: { [key: string]: ComponentWithAs<"svg", IconProps> } = {
  general: DemographicGeneralIcon,
  industryAndOccupation: DemographicIndustryIcon,
  financialProducts: DemographicFinancialProductsIcon,
  shopping: DemographicShoppingIcon,
  technology: DemographicTechnologyIcon,
  hobbiesAndInterests: DemographicHobbiesIcon,
}

const FALLBACK_ICON = DemographicGeneralIcon

export const DemographicAttributeGroupIcon: React.FC<Props> = ({
  groupName,
}) => {
  const camelCasedGroupName = camelCase(groupName)

  useEffect(() => {
    if (!(camelCasedGroupName in ICONS)) {
      reportErrorToSentry(
        new Error(
          `Could not find icon for demographic attribute group "${groupName}"; using fallback icon instead.`
        )
      )
    }
  }, [groupName])

  const icon =
    camelCasedGroupName in ICONS ? ICONS[camelCasedGroupName] : FALLBACK_ICON

  return <Icon as={icon} boxSize={4} color="ds.icon.subtle" />
}
