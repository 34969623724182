import { Box, Spinner } from "@chakra-ui/react"
import React, { ReactElement } from "react"
import { Link, useParams } from "react-router-dom"

import { Checkmark, Cross } from "Components/checkmark/Checkmark"
import { DateTime } from "Components/time/date-time"
import { CENTS_PER_CREDIT } from "Constants/testers"
import { DeleteIcon } from "Icons/DeleteIcon"
import { RestrictedIcon } from "Icons/RestrictedIcon"
import { TestCompleteFilledIcon } from "Icons/TestCompleteFilledIcon"
import { isNotFoundError } from "Services/axios"
import { usePushNotifications } from "Shared/hooks/usePushNotifications"
import { UserCrowdLogoIconLegacy } from "Shared/icons/UserCrowdLogoIconLegacy"
import {
  AutomaticDeletionReason,
  PanelistPublicDeletionReason,
  ParticipantDeletionReason,
  ReviewStatus,
} from "Types"
import {
  AssignmentList,
  AssignmentsHeading,
} from "UserCrowd/components/AssignmentList/AssignmentList"
import { H2 } from "UserCrowd/components/Heading/Heading"
import { Card } from "UserCrowd/components/TestersCard"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { ROUTES } from "UserCrowd/views/routes"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { reportErrorToSentry } from "Utilities/error"
import { isInProgress } from "Utilities/response"
import {
  ListUsercrowdOrderAssignmentsResponse,
  useGetUsercrowdOrderAssignment,
} from "~/api/generated/usabilityhub-components"
import styles from "./assignment.module.scss"

type AssignmentResponse =
  ListUsercrowdOrderAssignmentsResponse["panelist_order_assignments"][0]["response"]

function assertResponseSubmitted(
  response: AssignmentResponse
): response is NonNullable<AssignmentResponse> {
  if (!response || response.submitted_at === null) {
    reportErrorToSentry(new TypeError("response.submitted_at === null"), {
      extra: { response },
    })
  }

  return true
}

interface LayoutProps {
  readonly heading: string
  readonly cardProps?: React.ComponentProps<typeof Card>
  readonly icon: React.ReactNode | null
  readonly children: ReactElement
}

function AssignmentLayout({ heading, cardProps, icon, children }: LayoutProps) {
  return (
    <>
      <TestersHeader heading={heading} showBackButton />

      <Card {...cardProps}>
        <div className={styles.messageContainer}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.message}>{children}</div>
        </div>
      </Card>

      <Box>
        <AssignmentsHeading />
        <AssignmentList />
      </Box>
    </>
  )
}

interface ReviewSummaryProps {
  readonly reviewStatus: ReviewStatus | null
}

const ReviewSummary = ({ reviewStatus }: ReviewSummaryProps): ReactElement => {
  switch (reviewStatus) {
    case null:
      return <>Your response is awaiting review.</>
    case ReviewStatus.RejectedLegacy:
    case ReviewStatus.Rejected:
      return (
        <>
          Your response has been rejected. <Cross />
        </>
      )
    case ReviewStatus.AutoAccepted:
    case ReviewStatus.Accepted:
      return (
        <>
          Your response has been graded: <strong>Good</strong> <Checkmark />
        </>
      )
    case ReviewStatus.AcceptedLowQuality:
      return (
        <>
          Your response has been graded: <strong>Poor</strong>
        </>
      )
    case ReviewStatus.Void:
      return (
        <>
          This response has been removed and does not count towards your rating.
        </>
      )
  }
}
interface CompletedMessageProps {
  readonly response: AssignmentResponse
}

const CompletedMessage = ({ response }: CompletedMessageProps) => {
  if (!assertResponseSubmitted(response)) return null

  return (
    <>
      <H2>Thanks for your response!</H2>
      {response.submitted_at !== null && (
        <p>
          You completed this test at <DateTime date={response.submitted_at} />
          {response.point_transactions.length > 0 && (
            <>
              {" "}
              and earned{" "}
              <strong>
                {formatDollars(
                  centsToDollars(
                    response.point_transactions.reduce(
                      (acc, pt) => acc + pt.total_points,
                      0
                    ) * CENTS_PER_CREDIT
                  )
                )}
              </strong>
            </>
          )}
          .
        </p>
      )}
      <p>
        <ReviewSummary
          reviewStatus={response.review_status as ReviewStatus | null}
        />
      </p>
    </>
  )
}

const InProgressMessage = () => (
  <>
    <H2>You are currently taking this test.</H2>
    <p>
      If you have another test open in a different browser window, please
      complete the test.
    </p>
    <p>You cannot resume a test after closing the window.</p>
  </>
)

interface DeletedMessageProps {
  readonly response: AssignmentResponse
  readonly reason: PanelistPublicDeletionReason
}

function renderReason(reason: PanelistPublicDeletionReason): string {
  switch (reason) {
    case ParticipantDeletionReason.ConfusingTest:
      return "Test was confusing or poorly worded"
    case ParticipantDeletionReason.ImagesFailedToLoad:
      return "Test failed to load"
    case ParticipantDeletionReason.Inactive:
      return "Participant was inactive for too long"
    case ParticipantDeletionReason.InappropriateTest:
      return "Test was reported for inappropriate content"
    case ParticipantDeletionReason.IncorrectProfileLanguage:
      return "Participant did not speak the language of the test"
    case ParticipantDeletionReason.IncorrectTestLanguage:
      return "Test was in the wrong language"
    case ParticipantDeletionReason.TechnicalProblem:
      return "Technical problem"
    case ParticipantDeletionReason.Skipped:
      return "Skipped"
    case ParticipantDeletionReason.CanceledToStartAnotherResponse:
      return "Canceled to start another response"
    case ParticipantDeletionReason.TestRequestedPersonalInformation:
      return "Test was reported for requesting personal information"
    case ParticipantDeletionReason.TestSentMeOffsite:
      return "Test was reported for sending participant to another website"
    case ParticipantDeletionReason.RecordingPermissionDenied:
      return "Participant denied permission to record"
    case ParticipantDeletionReason.RecordingUploadFailed:
      return "Failed to upload recordings"
    case ParticipantDeletionReason.RecordingPrematureStop:
      return "Recording was stopped prematurely"
    case ParticipantDeletionReason.RecordingFailed:
      return "Something went wrong with the recording"
    case ParticipantDeletionReason.NoCompletionCode:
      return "Test did not provide a completion code"
    case ParticipantDeletionReason.ParticipantIdNotRequested:
      return "Test did not ask for my Participant ID"
    case AutomaticDeletionReason.Disconnected:
      return "Participant disconnected"
    case AutomaticDeletionReason.TimedOut:
      return "Participant did not complete the test within the allowed time"
    case AutomaticDeletionReason.Malicious:
      return "Response contained inappropriate language"
    case AutomaticDeletionReason.TooFast:
      return "Response was completed too quickly"
  }
}

const DeletedMessage = ({ response, reason }: DeletedMessageProps) => (
  <>
    <H2>Thanks for your response!</H2>
    {response && response.submitted_at !== null && (
      <p>
        This test was submitted at <DateTime date={response.submitted_at} />,
        but was not completed.
      </p>
    )}
    <p>
      Non-completion reason: <strong>{renderReason(reason)}</strong>
    </p>
  </>
)

// TODO: Make recommendation conditional.
const OrderInactiveMessage = () => {
  const { isEnabled } = usePushNotifications()

  return (
    <>
      <H2>So sorry! This test is no longer available.</H2>
      <p>
        Tests are available for a limited time only.{" "}
        {!isEnabled && (
          <>
            To avoid missing out again, we recommend enabling push notifications
            in your{" "}
            <Link to={ROUTES.SETTINGS.NOTIFICATIONS.path}>
              notification settings
            </Link>
            .
          </>
        )}
      </p>
    </>
  )
}

const ErrorMessage = ({ error }: { error: Error }) => {
  const is404 = isNotFoundError(error)
  return is404 ? (
    <>
      <H2>Test not found</H2>
      <p>This test may have been removed.</p>
    </>
  ) : (
    <>
      <H2>Something went wrong</H2>
      <p>{error.message}</p>
    </>
  )
}

type AssignmentRouteParams = {
  id: string
}

export const AssignmentRoute = () => {
  const { id } = useParams<AssignmentRouteParams>()
  const assignmentId = parseInt(id!)

  const {
    data: assignment,
    isLoading,
    error,
  } = useGetUsercrowdOrderAssignment({
    pathParams: {
      id: assignmentId,
    },
  })

  let layoutProps: LayoutProps
  if (error) {
    layoutProps = {
      heading: "Error",
      icon: null,
      cardProps: {
        barColor: "red.500",
      },
      children: <ErrorMessage error={new Error(error.payload.message)} />,
    }
  } else if (isLoading || !assignment) {
    layoutProps = {
      heading: "Loading…",
      icon: null,
      children: <Spinner size="xs" />,
    }
  } else if (assignment.response !== null) {
    if (isInProgress(assignment.response)) {
      layoutProps = {
        heading: "Test in progress",
        icon: <UserCrowdLogoIconLegacy />,
        cardProps: {
          barColor: "brand.primary.500",
        },
        children: <InProgressMessage />,
      }
    } else if (assignment.response.panelist_deletion_reason === null) {
      layoutProps = {
        heading: "Test completed",
        icon: <TestCompleteFilledIcon />,
        cardProps: {
          barColor: "green.500",
        },
        children: <CompletedMessage response={assignment.response} />,
      }
    } else {
      layoutProps = {
        heading: "Test was not completed",
        icon: <DeleteIcon />,
        cardProps: {
          barColor: "red.500",
        },
        children: (
          <DeletedMessage
            response={assignment.response}
            reason={
              assignment.response
                .panelist_deletion_reason as PanelistPublicDeletionReason
            }
          />
        ),
      }
    }
  } else if (assignment.order.state !== "active" || assignment.revoked) {
    layoutProps = {
      heading: "Test unavailable",
      icon: <RestrictedIcon />,
      cardProps: {
        barColor: "red.500",
      },
      children: <OrderInactiveMessage />,
    }
  } else {
    layoutProps = {
      heading: "Test available",
      icon: <UserCrowdLogoIconLegacy />,
      cardProps: {
        barColor: "brand.primary.500",
      },
      children: (
        <>
          <H2>You haven{"\u2019"}t taken this test</H2>
          <p>Click it in your list of tests below!</p>
        </>
      ),
    }
  }
  return (
    <UserCrowdDefaultPageLayout>
      <UserCrowdNavbar variant="inner-page" />
      <AssignmentLayout {...layoutProps} />
    </UserCrowdDefaultPageLayout>
  )
}
