import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Grid,
  Icon,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button as ButtonDS, IconButton, Tag } from "DesignSystem/components"
import { BalanceCircleFilledIcon } from "Icons/BalanceCircleFilledIcon"
import { CursorFilledIcon } from "Icons/CursorFilledIcon"
import { HelpCircleIcon } from "Icons/HelpCircleIcon"
import { HistoryIcon } from "Icons/HistoryIcon"
import { ManagePayoutsIcon } from "Icons/ManagePayoutsIcon"
import { UserCrowdRatingFilledIcon } from "Icons/UserCrowdRatingFilledIcon"
import { MetricsCard } from "Shared/components/MetricsCard"
import { ROUTES } from "UserCrowd/views/routes"
import { formatDollars } from "Utilities/currency"
import { formatNumber } from "Utilities/number"
import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { usePanelistStats } from "~/api/generated/usabilityhub-components"

export const TestersMetrics: React.FC = () => {
  const { data, isError, refetch } = usePanelistStats({}, { retry: false })

  const isMediumOnly = useBreakpointValue<boolean>(
    {
      base: false,
      md: true,
      lg: false,
    },
    { ssr: false }
  )

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Flex direction="column" align="flex-start" gap={2}>
          <AlertDescription>Error loading metrics</AlertDescription>
          <Button size="xs" colorScheme="red" onClick={() => refetch()}>
            Retry
          </Button>
        </Flex>
      </Alert>
    )
  }

  const currentBalanceValue = data
    ? formatDollars(data.credit_balance.current)
    : undefined
  const completedStudiesValue = data
    ? data.studies_completed.tests + data.studies_completed.interviews
    : undefined
  const ratingRaw =
    data && data.rating
      ? parseFloat(((data.rating / 100) * 5).toFixed(2))
      : undefined
  const ratingValue = ratingRaw
    ? !(ratingRaw % 1) || !((ratingRaw * 100) % 10)
      ? formatNumber(ratingRaw, 1)
      : formatNumber(ratingRaw, 2)
    : undefined

  return (
    <Grid
      gap={4}
      gridTemplate={["1fr 1fr 1fr / 1fr", null, "1fr / repeat(3, 1fr)"]}
    >
      <MetricsCard>
        <MetricsCard.TagAndAction
          tag={<Tag label="Balance" leftIcon={BalanceCircleFilledIcon} />}
          action={
            isMediumOnly ? (
              <IconButton
                variant="secondary"
                size="compact"
                aria-label="Manage payouts"
                icon={<ManagePayoutsIcon />}
                as={ReactRouterLink}
                to={ROUTES.PAYOUTS.path}
              />
            ) : (
              <ButtonDS
                variant="secondary"
                size="compact"
                as={ReactRouterLink}
                to={ROUTES.PAYOUTS.path}
              >
                Manage payouts
              </ButtonDS>
            )
          }
        />
        <MetricsCard.Info
          value={currentBalanceValue}
          description="Current balance"
        />
      </MetricsCard>
      <MetricsCard>
        <MetricsCard.TagAndAction
          tag={<Tag label="Studies" leftIcon={CursorFilledIcon} />}
          action={
            isMediumOnly ? (
              <IconButton
                variant="secondary"
                size="compact"
                aria-label="See history"
                icon={<HistoryIcon />}
                as={ReactRouterLink}
                to={ROUTES.HISTORY.path}
              />
            ) : (
              <ButtonDS
                variant="secondary"
                size="compact"
                as={ReactRouterLink}
                to={ROUTES.HISTORY.path}
              >
                See history
              </ButtonDS>
            )
          }
        />
        <MetricsCard.Info
          value={completedStudiesValue}
          description="Studies completed"
        />
      </MetricsCard>
      <MetricsCard>
        <MetricsCard.TagAndAction
          tag={<Tag label="Rating" leftIcon={UserCrowdRatingFilledIcon} />}
          action={
            <IconButton
              variant="secondary"
              size="compact"
              aria-label="Rating info"
              icon={<HelpCircleIcon />}
              as={Link}
              href={Constants.PANELIST_HELP_CENTER_TESTER_GUIDELINES_URL}
            />
          }
        />
        <MetricsCard.Info
          value={
            ratingValue ? (
              <Flex align="center">
                {ratingValue}
                <Icon
                  as={UserCrowdRatingFilledIcon}
                  fontSize={16}
                  color="yellow.500"
                  ml={1}
                />
              </Flex>
            ) : (
              "Pending"
            )
          }
          description="Out of 5"
        />
      </MetricsCard>
    </Grid>
  )
}
