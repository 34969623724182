import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RecordScreenFilledIcon = createIcon({
  displayName: "RecordScreenFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.244 2c.266 0 .517 0 .753.002A4.992 4.992 0 0 0 16 6c0 .711.148 1.388.416 2H2.602c-.57 0-.855 0-1.073-.113a1.02 1.02 0 0 1-.438-.453c-.106-.22-.098-.492-.08-1.034.006-.218.017-.435.035-.652.046-.562.145-1.079.392-1.564a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C5.291 2 5.956 2 6.76 2h10.484ZM22.924 10.616A4.986 4.986 0 0 1 21 11a4.978 4.978 0 0 1-3-1H2.602c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437c-.109.214-.109.494-.109 1.054v4.641c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C5.291 22 5.956 22 6.76 22h10.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V11.6c0-.5 0-.778-.078-.984ZM21 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill="currentColor"
    />
  ),
})
