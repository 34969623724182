import {
  Box,
  Button,
  Image,
  Input,
  InputProps,
  Text,
  useOutsideClick,
} from "@chakra-ui/react"
import { useDebouncedValue } from "Shared/hooks/useDebouncedValue"
import React, { useRef, useState } from "react"
import poweredByGoogleImage from "./powered-by-google.png"
import { useGooglePlacesAutocomplete } from "./useGooglePlacesAutocomplete"

type Props = {
  initialPlaceId: string | null
  initialFormattedAddress: string | null
  onChange: (placeId: string, formattedAddress: string) => void
} & Omit<InputProps, "onChange" | "defaultValue">

export const GooglePlacesAutocomplete: React.FC<Props> = ({
  initialPlaceId,
  initialFormattedAddress,
  onChange,
  ...inputProps
}) => {
  const [search, setSearch] = useState(initialFormattedAddress ?? "")
  const [placeId, setPlaceId] = useState<string | null>(initialPlaceId)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const autocompleteMenuRef = useRef<HTMLDivElement>(null)

  useOutsideClick({
    ref: autocompleteMenuRef,
    handler: () => setIsMenuOpen(false),
  })

  const debouncedSearch = useDebouncedValue(search, 500)
  const { data: searchData, isLoading: isSearchDataLoading } =
    useGooglePlacesAutocomplete(debouncedSearch)

  return (
    <>
      <Input
        placeholder="Enter your suburb, town or postcode…"
        value={search}
        onChange={(e) => {
          if (e.target.value === "") {
            setPlaceId(null)
            setIsMenuOpen(false)
          } else {
            setIsMenuOpen(true)
          }

          setSearch(e.target.value)
        }}
        data-1p-ignore // prevents 1Password from blocking filter results
        data-lpignore="true" // same for LastPass
        data-bwignore // same for Bitwarden
        {...inputProps}
      />

      {isMenuOpen && (
        <Box position="relative" mt={1} w="full">
          <Box
            pos="relative" // So we can position the "Powered by Google" image below
            ref={autocompleteMenuRef}
            bg="white"
            rounded="md"
            shadow="md"
            overflow="hidden"
            w="100%"
            borderWidth={1}
          >
            {isSearchDataLoading ? (
              <Text color="text.secondary" fontSize="sm" p={4}>
                Loading suggestions...
              </Text>
            ) : searchData?.suggestions ? (
              searchData.suggestions.map(({ placePrediction }) => {
                return (
                  <Button
                    variant="unstyled"
                    fontWeight="normal"
                    fontSize="sm"
                    rounded="none"
                    w="full"
                    h="auto"
                    textAlign="left"
                    cursor="pointer"
                    key={placePrediction.placeId}
                    onClick={() => {
                      setIsMenuOpen(false)
                      setSearch(placePrediction.text.text)

                      if (placeId !== placePrediction.placeId) {
                        setPlaceId(placePrediction.placeId)
                        onChange(
                          placePrediction.placeId,
                          placePrediction.text.text
                        )
                      }
                    }}
                    _hover={{ bg: "gray.100" }}
                    px={4}
                    py={2}
                  >
                    {placePrediction.text.text}
                  </Button>
                )
              })
            ) : (
              <Text color="text.secondary" fontSize="sm" p={4}>
                We couldn't find a matching address.
              </Text>
            )}

            <Box
              pos="absolute"
              right={0}
              bottom={0}
              px={3}
              py={1}
              borderTopLeftRadius="lg"
              bgColor="ds.background.accent.gray.subtler"
            >
              <Image
                w="114px"
                src={poweredByGoogleImage}
                alt="Powered by Google"
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
