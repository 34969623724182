import { Box, Flex, Link, Text } from "@chakra-ui/react"
import { Alert, Button } from "DesignSystem/components"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import { DeviceType, PermittedDeviceType } from "Types"
import { isDeviceTypePermitted } from "Utilities/permittedDeviceType"
import React, { PropsWithChildren, ReactNode } from "react"

type Href = string
type OnClickHandler = () => void

type Props = {
  studyTypeTag: ReactNode
  incentiveIcon?: ReactNode
  incentiveText: string | ReactNode
  estimatedLengthText: string
  tags?: ReactNode
  participateAction: Href | OnClickHandler
  participateLabel: string
  rejectAction?: OnClickHandler
  currentDeviceType?: DeviceType
  permittedDeviceType?: PermittedDeviceType
  disableParticipationButton?: boolean
}

const BREAKPOINT_MAX = "30rem"

const SWITCH_DEVICE_MESSAGES: Partial<Record<PermittedDeviceType, string>> = {
  desktop_only:
    "Mobile devices are not supported for this test. Please switch to desktop to continue.",
  mobile_only:
    "Desktop devices are not supported for this test. Please switch to mobile to continue.",
}

export const AssignmentCard: React.FC<PropsWithChildren<Props>> = ({
  studyTypeTag,
  incentiveIcon,
  incentiveText,
  estimatedLengthText,
  tags = [],
  children,
  participateAction,
  participateLabel,
  rejectAction,
  currentDeviceType,
  permittedDeviceType,
  disableParticipationButton,
}) => {
  const isWrongDeviceType =
    currentDeviceType && permittedDeviceType
      ? !isDeviceTypePermitted(currentDeviceType, permittedDeviceType)
      : false
  const participationDisabled = isWrongDeviceType || disableParticipationButton

  return (
    <Box sx={cqContainer()}>
      <Box
        rounded="12px"
        bg="ds.surface.raised.resting"
        boxShadow="ds.raised"
        p={4}
        sx={cq(BREAKPOINT_MAX, { p: 6, rounded: "16px" })}
      >
        <Flex
          gap={4}
          flexDirection="column"
          sx={cq(BREAKPOINT_MAX, { flexDirection: "row", gap: 6 })}
        >
          <Flex
            flexDirection="column"
            align="flex-start"
            gap={4}
            sx={cq(BREAKPOINT_MAX, { flexBasis: "7.5rem", flexShrink: 0 })}
          >
            {studyTypeTag}
            <Flex direction="column" gap={2}>
              <Flex align="center" gap={2}>
                {incentiveIcon}
                <Text textStyle="ds.display.primary" color="ds.text.default">
                  {incentiveText}
                </Text>
              </Flex>
              <Text
                textStyle="ds.paragraph.secondary"
                color="ds.text.subtle"
                whiteSpace="nowrap"
              >
                {estimatedLengthText}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" align="flex-start" gap={6}>
            <Flex direction="column" gap={4}>
              {tags && <Flex gap={2}>{tags}</Flex>}
              {children}
            </Flex>
            <Flex gap={2}>
              <ParticipateButton
                hrefOrHandler={participateAction}
                label={participateLabel}
                isDisabled={participationDisabled}
              />
              {rejectAction && <DismissButton handler={rejectAction} />}
            </Flex>
            {isWrongDeviceType && permittedDeviceType !== undefined && (
              <Alert
                status="warning"
                description={SWITCH_DEVICE_MESSAGES[permittedDeviceType]}
              />
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

const ParticipateButton: React.FC<{
  hrefOrHandler: Props["participateAction"]
  label: string
  isDisabled?: boolean
}> = ({ hrefOrHandler, label, isDisabled }) => {
  return typeof hrefOrHandler === "string" ? (
    <Button
      as={Link}
      variant="primary"
      href={isDisabled ? undefined : hrefOrHandler}
      isDisabled={isDisabled}
    >
      {label}
    </Button>
  ) : (
    <Button variant="primary" onClick={hrefOrHandler} isDisabled={isDisabled}>
      {label}
    </Button>
  )
}

const DismissButton: React.FC<{
  handler: OnClickHandler
}> = ({ handler }) => {
  return (
    <Button variant="secondary" onClick={handler}>
      Dismiss
    </Button>
  )
}
