import { Flex } from "@chakra-ui/react"
import { Tag } from "DesignSystem/components"
import { RecordThinkAloudFilledIcon } from "Icons/RecordThinkAloudFilledIcon"
import { ScreenerFilledIcon } from "Icons/ScreenerFilledIcon"
import { PermittedDeviceType } from "Types"
import React from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { STUDY_DETAIL_TAGS_CONTENT } from "./assignmentCardStudyDetailTagsContent"

type AssignmentCardStudyDetailTagsProps = {
  recordingTypes?: RecordingType[]
  permittedDeviceType?: PermittedDeviceType
  hasScreener?: boolean
}

export const AssignmentCardStudyDetailTags: React.FC<
  AssignmentCardStudyDetailTagsProps
> = ({ recordingTypes, permittedDeviceType, hasScreener }) => {
  return (
    <Flex flexWrap="wrap" gap={2}>
      {hasScreener && <ScreenerTag />}
      {permittedDeviceType && (
        <DeviceTag permittedDeviceType={permittedDeviceType} />
      )}
      {recordingTypes && <RecordingTags recordingTypes={recordingTypes} />}
      {recordingTypes?.includes("microphone") && <ThinkAloudTag />}
    </Flex>
  )
}

const ScreenerTag: React.FC = () => {
  return <Tag variant="subtle" label="Screener" leftIcon={ScreenerFilledIcon} />
}

const DeviceTag: React.FC<{
  permittedDeviceType: PermittedDeviceType
}> = ({ permittedDeviceType }) => {
  return (
    <Tag
      variant="subtle"
      label={
        STUDY_DETAIL_TAGS_CONTENT.permittedDeviceType[permittedDeviceType].label
      }
      leftIcon={
        STUDY_DETAIL_TAGS_CONTENT.permittedDeviceType[permittedDeviceType].icon
      }
    />
  )
}

const RECORDING_TYPE_ORDER: RecordingType[] = ["camera", "microphone", "screen"]

const RecordingTags: React.FC<{
  recordingTypes: RecordingType[]
}> = ({ recordingTypes }) => {
  return recordingTypes
    .toSorted(
      (a, b) =>
        RECORDING_TYPE_ORDER.indexOf(a) - RECORDING_TYPE_ORDER.indexOf(b)
    )
    .map((rt) => (
      <Tag
        key={rt}
        variant="subtle"
        label={STUDY_DETAIL_TAGS_CONTENT.recordingTypes[rt].label}
        leftIcon={STUDY_DETAIL_TAGS_CONTENT.recordingTypes[rt].icon}
      />
    ))
}

const ThinkAloudTag: React.FC = () => {
  return (
    <Tag
      variant="subtle"
      label="Think aloud"
      leftIcon={RecordThinkAloudFilledIcon}
    />
  )
}
