import { Center, Flex, Spinner } from "@chakra-ui/react"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdLogo } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { TestersPhoneForm } from "./PhoneForm"

export const PhoneRoute: React.FC = () => {
  const { data } = useCurrentPanelistInfo({})

  if (!data) {
    return <Spinner />
  }

  if (data.phone_number !== null) {
    return <Navigate to={ROUTES.NOTIFICATION_PREFERENCES.path} />
  }

  return (
    <UserCrowdDefaultPageLayout hasNoNavbar>
      <Center minH="full">
        <Helmet title="Verify your phone number" />
        <Flex flexDirection="column" gap={10}>
          <UserCrowdLogo />

          <TestersPhoneForm />
        </Flex>
      </Center>
    </UserCrowdDefaultPageLayout>
  )
}
