import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MicrophoneFilledIcon = createIcon({
  displayName: "MicrophoneFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11C4.55228 11 5 11.4477 5 12V13C5 16.866 8.13401 20 12 20C15.866 20 19 16.866 19 13V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13V12C3 11.4477 3.44772 11 4 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V13C17 15.7614 14.7614 18 12 18C9.23858 18 7 15.7614 7 13V7Z"
        fill="currentColor"
      />
    </>
  ),
})
