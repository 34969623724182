import { Box, Flex } from "@chakra-ui/react"
import { Tag } from "DesignSystem/components"
import { OnboardingIcon } from "Icons/OnboardingIcon"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import React from "react"
import { useOnboardingDrawerContext } from "./OnboardingDrawerContextProvider"
import { OnboardingDrawerInstructions } from "./OnboardingDrawerInstructions"
import { OnboardingDrawerProgress } from "./OnboardingDrawerProgress"
import { OnboardingDrawerSteps } from "./OnboardingDrawerSteps"
import { BREAKPOINT_MID } from "./constants"

type Props = {
  isDrawer?: boolean
}

export const OnboardingDrawer: React.FC<Props> = ({ isDrawer }) => {
  const {
    showDrawer,
    practiceTestStatus,
    isProfileStepCompleted,
    stepsCompletedCount,
    handleAllDoneClick,
  } = useOnboardingDrawerContext()

  if (!showDrawer) return null

  return (
    <Box sx={cqContainer()} h="full">
      <Flex
        h="full"
        bg="ds.background.neutral.resting"
        rounded={isDrawer ? "0px" : "16px"}
        flexDirection="column"
        gap={6}
        pt={isDrawer ? 6 : 4}
        pb={2}
        sx={cq(BREAKPOINT_MID, { pt: 6, pb: 3 })}
      >
        <Flex
          flexDirection="column"
          gap={6}
          px={isDrawer ? 6 : 4}
          alignItems="flex-start"
          sx={cq(BREAKPOINT_MID, { px: 6 })}
        >
          <Tag label="Onboarding" leftIcon={OnboardingIcon} />
          <OnboardingDrawerInstructions />
          <OnboardingDrawerProgress />
        </Flex>
        <OnboardingDrawerSteps
          isDrawer={isDrawer}
          practiceTestStatus={practiceTestStatus}
          isProfileStepCompleted={isProfileStepCompleted}
          stepsCompletedCount={stepsCompletedCount}
          handleAllDoneClick={handleAllDoneClick}
        />
      </Flex>
    </Box>
  )
}
