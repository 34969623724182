import { createIcon } from "@chakra-ui/react"
import React from "react"

export const DeviceMobileFilledIcon = createIcon({
  displayName: "DeviceMobileFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8385 1H8.16146C7.63433 0.999984 7.17954 0.999969 6.80497 1.03057C6.40963 1.06287 6.01641 1.13419 5.63803 1.32698C5.07354 1.6146 4.6146 2.07354 4.32698 2.63803C4.13419 3.01641 4.06287 3.40963 4.03057 3.80497C3.99997 4.17954 3.99998 4.63429 4 5.16142V18.8385C3.99998 19.3657 3.99997 19.8205 4.03057 20.195C4.06287 20.5904 4.13419 20.9836 4.32698 21.362C4.6146 21.9265 5.07354 22.3854 5.63803 22.673C6.01641 22.8658 6.40963 22.9371 6.80497 22.9694C7.17954 23 7.6343 23 8.16144 23H15.8386C16.3657 23 16.8205 23 17.195 22.9694C17.5904 22.9371 17.9836 22.8658 18.362 22.673C18.9265 22.3854 19.3854 21.9265 19.673 21.362C19.8658 20.9836 19.9371 20.5904 19.9694 20.195C20 19.8205 20 19.3658 20 18.8386V5.16148C20 4.63437 20 4.17952 19.9694 3.80497C19.9371 3.40963 19.8658 3.01641 19.673 2.63803C19.3854 2.07354 18.9265 1.6146 18.362 1.32698C17.9836 1.13419 17.5904 1.06287 17.195 1.03057C16.8205 0.999969 16.3657 0.999984 15.8385 1ZM12 16C11.1716 16 10.5 16.6716 10.5 17.5C10.5 18.3284 11.1716 19 12 19C12.8284 19 13.5 18.3284 13.5 17.5C13.5 16.6716 12.8284 16 12 16Z"
      fill="currentColor"
    />
  ),
})
