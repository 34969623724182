import {
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import React from "react"

import { Card } from "UserCrowd/components/TestersCard"

import Constants from "Constants/shared.json"
import usercrowdLogoSrc from "Images/usercrowd-logo.png"

export const NotFound = () => {
  return (
    <Flex direction="column" gap={3} align="center" mt={4}>
      <Image src={usercrowdLogoSrc} w="150px" mb={4} />

      <Heading as="h2" fontSize="md" fontWeight="normal">
        That page could not be found
      </Heading>

      <Heading as="h4" fontSize="md" fontWeight="normal">
        Sorry about that. It{"\u2019"}s probably not your fault.
      </Heading>

      <Card display="flex" flexDirection="column" gap={4} mt={4}>
        <Text>
          Send us an{" "}
          <Link href={`mailto:${Constants.TESTER_SUPPORT_EMAIL_ADDRESS}`}>
            email
          </Link>{" "}
          or{" "}
          <Link
            target="_blank"
            rel="noopener noreferer"
            href="https://twitter.com/user_crowd"
          >
            tweet
          </Link>{" "}
          if you{"\u2019"}re having trouble and we{"\u2019"}ll help out.
        </Text>

        <Text>For now, maybe you want to go to one of these pages:</Text>

        <UnorderedList>
          <ListItem>
            <Link href={Constants.PANELIST_MARKETING_DOMAIN}>
              The UserCrowd home page
            </Link>
          </ListItem>
          <ListItem>
            <Link href="/dashboard">Your account dashboard</Link>
          </ListItem>
        </UnorderedList>

        <Text>&ndash; Team UserCrowd</Text>
      </Card>
    </Flex>
  )
}
