import { Box, Flex, Grid, Text, useBreakpointValue } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { TipIcon } from "Icons/TipIcon"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import { dashboardGreeting } from "Shared/helpers/dashboardGreeting"
import {
  AssignmentList,
  AssignmentsHeading,
} from "UserCrowd/components/AssignmentList/AssignmentList"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { USERCROWD_NAVBAR_HEIGHT } from "UserCrowd/constants"
import { sample } from "lodash"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Outlet } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { ProfileCompletionBanner } from "./ProfileCompletionBanner/ProfileCompletionBanner"
import { TestersMetrics } from "./TestersMetrics"
import { UpcomingBookingList } from "./UpcomingBookingList"
import { LiveWebsiteTestingLaunchBanner } from "./live-website-testing-launch/LiveWebsiteTestingLaunchBanner"
import { OnboardingDrawer } from "./onboarding-drawer/OnboardingDrawer"
import {
  OnboardingDrawerContextProvider,
  useOnboardingDrawerContext,
} from "./onboarding-drawer/OnboardingDrawerContextProvider"
import { testerGuidelines } from "./testerGuidelines"

export const DashboardRoute: React.FC = () => {
  const [guideline] = useState(() => sample(testerGuidelines))

  const { data: user } = useCurrentPanelistInfo(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )

  const shouldInlineOnboardingDrawer = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { fallback: "xl" }
  )

  return (
    <>
      <UserCrowdNavbar variant="dashboard" />
      <LiveWebsiteTestingLaunchBanner />
      <OnboardingDrawerContextProvider>
        <OnboardingDrawerAndContentWrapper
          displayAsDrawer={!shouldInlineOnboardingDrawer}
        >
          <UserCrowdDefaultPageLayout>
            <Flex direction="column" gap={8} sx={cqContainer()}>
              <Helmet>
                <title>Dashboard • UserCrowd</title>
              </Helmet>
              {/* The dashboard renders some modals on their own nested routes. See UserCrowd.tsx for more info. */}
              <Outlet />
              <TestersHeader
                heading={dashboardGreeting(user ?? null)}
                showBackButton={false}
                mb={0}
              />
              {shouldInlineOnboardingDrawer && <OnboardingDrawer />}
              <TestersMetrics />
              <ProfileCompletionBanner />
              <Flex flexDirection="column" gap={4}>
                <Grid
                  templateColumns="1fr"
                  gap={6}
                  sx={{
                    ...cq("50rem", { gridTemplateColumns: "2fr 1fr", gap: 8 }),
                    ...cq("60rem", { gap: 10 }),
                  }}
                >
                  <Flex direction="column" gap={4}>
                    <AssignmentsHeading />
                    <AssignmentList />
                  </Flex>
                  <Flex direction="column" gap={4}>
                    <UpcomingBookingList />
                    {guideline && (
                      <Alert
                        status="default"
                        icon={TipIcon}
                        title={guideline.heading}
                        description={
                          <Flex flexDirection="column" gap={2}>
                            {guideline.guideline.map((paragraph) => (
                              <Text key={paragraph}>{paragraph}</Text>
                            ))}
                          </Flex>
                        }
                      ></Alert>
                    )}
                  </Flex>
                </Grid>
              </Flex>
            </Flex>
          </UserCrowdDefaultPageLayout>
        </OnboardingDrawerAndContentWrapper>
      </OnboardingDrawerContextProvider>
    </>
  )
}

const OnboardingDrawerAndContentWrapper: React.FC<
  React.PropsWithChildren<{
    displayAsDrawer?: boolean
  }>
> = ({ displayAsDrawer, children }) => {
  const { showDrawer } = useOnboardingDrawerContext()

  if (!showDrawer) return children
  if (!displayAsDrawer) return children

  return (
    <Flex minH="100dvh" alignItems="stretch">
      <Box mt={USERCROWD_NAVBAR_HEIGHT} flexBasis="21rem" flexShrink={0}>
        <OnboardingDrawer isDrawer />
      </Box>
      {children}
    </Flex>
  )
}
