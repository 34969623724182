import { Flex, Image, Text } from "@chakra-ui/react"
import { Badge, Button, Heading } from "DesignSystem/components"
import { DisplayModal } from "Shared/components/DisplayModal/DisplayModal"
import React from "react"
import { Navigate, useNavigate } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../../routes"
import illustrationSrc from "./start-practice-test-illustration.png"

const STATES_WHERE_YOU_CAN_DO_A_PRACTICE_TEST = ["not_started", "in_progress"]

export const StartPracticeTestRoute: React.FC = () => {
  const navigate = useNavigate()
  const { data: user, status } = useCurrentPanelistInfo({})

  const onClose = () => {
    navigate(ROUTES.DASHBOARD.path)
  }

  if (status !== "success") return null
  if (
    !STATES_WHERE_YOU_CAN_DO_A_PRACTICE_TEST.includes(user.practice_test_state)
  ) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <DisplayModal isOpen onClose={onClose}>
      <DisplayModal.TwoPaneContent
        content={
          <Image
            h="full"
            w="full"
            objectFit="cover"
            roundedTop="16px"
            roundedBottom={[0, "16px"]}
            src={illustrationSrc}
          />
        }
        controls={
          <>
            <Button variant="secondary" onClick={onClose}>
              Later
            </Button>

            <Button as="a" variant="primary" href={ROUTES.PRACTICE_TEST.path}>
              Let{"\u2019"}s go!
            </Button>
          </>
        }
      >
        <Flex direction="column" align="flex-start" gap={4}>
          <Badge variant="subtle" label="PRACTICE TEST" />

          <Heading
            as="h2"
            color="ds.text.default"
            textStyle="ds.display.primary"
          >
            You{"\u2019"}re about to begin a practice test
          </Heading>

          <Text color="ds.text.default" textStyle="ds.paragraph.emphasized">
            Your response will be reviewed by the UserCrowd Team, so please read
            all questions and answer them carefully.
          </Text>

          <Text color="ds.text.subtle" textStyle="ds.paragraph.primary">
            Note: There is no financial compensation for completing practice
            tests.
          </Text>
        </Flex>
      </DisplayModal.TwoPaneContent>
    </DisplayModal>
  )
}
