import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ScreenerFilledIcon = createIcon({
  displayName: "ScreenerFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.161 1h6.678c.527 0 .981 0 1.356.03.395.033.789.104 1.167.297a3 3 0 0 1 1.311 1.311c.193.378.264.772.296 1.167.031.375.031.83.031 1.356V6h-3.88c-.49 0-1.02 0-1.478.037-.519.043-1.205.148-1.912.508A5 5 0 0 0 6.545 8.73c-.36.707-.465 1.393-.508 1.912C6 11.101 6 11.631 6 12.12V16h-.839c-.527 0-.981 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167C1 12.82 1 12.365 1 11.839V5.16c0-.527 0-.981.03-1.356.033-.395.104-.789.297-1.167a3 3 0 0 1 1.311-1.311c.378-.193.772-.264 1.167-.296C4.18 1 4.635 1 5.161 1ZM18.838 8h-6.677c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.296 1.167C8 11.18 8 11.635 8 12.162v6.676c0 .528 0 .982.03 1.357.033.395.104.789.297 1.167a3 3 0 0 0 1.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.356.031h6.678c.527 0 .982 0 1.356-.03.395-.033.789-.104 1.167-.297a3 3 0 0 0 1.311-1.311c.193-.378.264-.772.296-1.167.031-.375.031-.83.031-1.356v-6.677c0-.528 0-.982-.03-1.357-.033-.395-.104-.789-.297-1.167a3 3 0 0 0-1.311-1.311c-.378-.193-.772-.264-1.167-.296A17.9 17.9 0 0 0 18.838 8Zm-4.282 5.191a2.073 2.073 0 0 1 1.168-.17c.413.057.753.229.976.444.218.211.3.439.3.635v.002c0 .02-.005.096-.138.24-.138.15-.354.307-.626.455a5.582 5.582 0 0 1-.995.415l-.063.02-.014.003a1 1 0 0 0 .526 1.93l-.263-.965.264.965.004-.002.01-.002.03-.009.1-.03a7.691 7.691 0 0 0 1.357-.568c.371-.202.798-.483 1.142-.857.35-.38.666-.916.666-1.595v.001l-1-.001h1c.001-.795-.344-1.526-.909-2.073-.56-.543-1.306-.88-2.09-.99-.785-.109-1.6.009-2.31.348-.708.34-1.298.899-1.609 1.619a1 1 0 0 0 1.836.792c.093-.214.3-.444.638-.607ZM15.461 18a1 1 0 1 0 0 2h.011a1 1 0 0 0 0-2h-.011Z"
      fill="currentColor"
    />
  ),
})
