import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Center,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { DisplayDate } from "Components/time/display-date"
import { DISPLAY_STRING_BY_VALUE } from "Constants/point-transactions"
import { TimeIcon } from "Icons/TimeIcon"
import { PointTransactionReason } from "Types"
import { EmptyListPlaceholder } from "UserCrowd/components/EmptyListPlaceholder"
import { Card, CardList, CardListItem } from "UserCrowd/components/TestersCard"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { ROUTES } from "UserCrowd/views/routes"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import React from "react"
import { Helmet } from "react-helmet"
import ReactPaginate from "react-paginate"
import { useNavigate } from "react-router"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  PanelistHistoryResponse,
  usePanelistHistory,
  usePanelistStats,
} from "~/api/generated/usabilityhub-components"

export const HistoryRoute: React.FC = () => {
  const { page = 1 } = useTypedParams(ROUTES.HISTORY)

  // All the pagination is zero-indexed internally but it's nicer for the users to start from 1
  const currentOffset = page - 1

  const { data: panelistStats } = usePanelistStats({})
  const { data: panelistHistory, isError } = usePanelistHistory({
    queryParams: { page: currentOffset },
  })

  return (
    <UserCrowdDefaultPageLayout>
      <Helmet>
        <title>History • UserCrowd</title>
      </Helmet>
      <UserCrowdNavbar variant="inner-page" />
      <TestersHeader heading="History" />

      {panelistStats && panelistStats.credit_balance.pending > 0 && (
        <Alert mb={4}>
          <AlertIcon>
            <TimeIcon boxSize={6} color="blue.500" />
          </AlertIcon>
          <AlertDescription>
            <strong>
              You have {formatDollars(panelistStats.credit_balance.pending)}{" "}
              pending.
            </strong>{" "}
            Pending balance from interviews will be released to your account
            when the researcher confirms completion within 5 days.
          </AlertDescription>
        </Alert>
      )}

      {panelistHistory ? (
        <PointTransactionList
          history={panelistHistory}
          currentOffset={currentOffset}
        />
      ) : isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            There was a problem retrieving your history data.
          </AlertDescription>
        </Alert>
      ) : (
        <Center minH={40}>
          <Spinner />
        </Center>
      )}
    </UserCrowdDefaultPageLayout>
  )
}

const PointTransactionList: React.FC<{
  history: PanelistHistoryResponse
  currentOffset: number
}> = ({ history, currentOffset }) => {
  const navigate = useNavigate()
  const pageCount = history.total_pages

  const handlePageClick = async ({ selected }: { selected: number }) => {
    navigate(ROUTES.HISTORY.buildPath({ page: selected + 1 }))
  }

  if (history.point_transactions.length === 0) {
    return (
      <EmptyListPlaceholder heading="No studies completed">
        <Text fontSize="md" color="text.secondary">
          You haven{"\u2019"}t participated in a study yet
        </Text>
      </EmptyListPlaceholder>
    )
  }

  return (
    <>
      <Box mb={4}>
        <Card p="none">
          <CardList>
            {history.point_transactions.map((pointTransaction) => (
              <CardListItem key={pointTransaction.id}>
                <Flex justify="space-between">
                  <Text>
                    {
                      DISPLAY_STRING_BY_VALUE[
                        pointTransaction.reason as PointTransactionReason
                      ]
                    }{" "}
                    on{" "}
                    {pointTransaction.updated_at && (
                      <DisplayDate date={pointTransaction.updated_at} />
                    )}
                  </Text>
                  <Text fontWeight="semibold" color="green.500">
                    {formatDollars(
                      centsToDollars(
                        creditsToCents(pointTransaction.karma_points)
                      )
                    )}
                  </Text>
                </Flex>
              </CardListItem>
            ))}
          </CardList>
        </Card>
      </Box>

      <Flex
        justifyContent="center"
        fontSize="md"
        sx={{
          ul: { display: "flex", listStyleType: "none" },
          li: { px: 2 },
          a: { whiteSpace: "nowrap", color: "brand.primary.500" },
          ".selected a": { color: "black" },
        }}
      >
        <ReactPaginate
          previousLabel="&larr; Previous"
          nextLabel="Next &rarr;"
          breakLabel="&hellip;"
          forcePage={currentOffset}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
        />
      </Flex>
    </>
  )
}
