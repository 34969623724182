import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button } from "DesignSystem/components"
import { AlertCircleFilledIcon } from "Icons/AlertCircleFilledIcon"
import { signedUpGoogle } from "JavaScripts/analytics/google"
import { getApplicationState } from "JavaScripts/state"
import { isValidEmail } from "Utilities/email"
import { reportErrorToSentry } from "Utilities/error"
import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import { useSignUpPanelist } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "~/usercrowd/views/routes"
import { CountrySelect } from "./CountrySelect"

type SignUpFormValues = {
  fullName: string
  preferredName: string
  email: string
  password: string
  acceptTerms: boolean
  country: string
}

export const SignUpForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  )
  const { recaptchaSiteKey } = getApplicationState()
  const recaptchaRef = React.useRef<ReCAPTCHA>(null)
  const resetRecaptchaResponse = () => {
    recaptchaRef.current?.reset()
  }

  const { mutate: signUpPanelist, isLoading } = useSignUpPanelist({
    onError: (error) => {
      toast({
        title: "Error",
        description:
          error.status === 400
            ? error.payload.message
            : "Sorry, something went wrong. Please try again.",
        isClosable: true,
        position: "top-right",
        status: "error",
      })
      if (error.status !== 400)
        reportErrorToSentry(new Error(error.payload.message))
      resetRecaptchaResponse()
    },
    onSuccess: () => {
      signedUpGoogle("tester_form")
      navigate(ROUTES.AGREEMENT.path)
    },
  })

  const isRecaptchaDisabled =
    recaptchaSiteKey !== null && recaptchaResponse === null

  const onSubmit = (values: SignUpFormValues) => {
    if (isRecaptchaDisabled) {
      toast({
        title: "Please complete the captcha",
        status: "error",
      })
      return
    }

    if (!recaptchaResponse) return

    signUpPanelist({
      body: {
        user: {
          full_name: values.fullName,
          preferred_name: values.preferredName,
          country: values.country,
          email: values.email,
          password: values.password,
          accept_terms: values.acceptTerms,
        },
        "g-recaptcha-response": recaptchaResponse,
      },
    })
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignUpFormValues>({
    mode: "onSubmit",
  })

  return (
    <>
      <Flex
        flexDirection="column"
        bg="white"
        color="ds.text.default"
        borderRadius="16px"
        boxShadow="ds.raised"
        p={6}
        gap={6}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDirection="column" gap={6}>
            <FormControl isInvalid={!!errors?.fullName}>
              <Flex flexDirection="column">
                <FormLabel
                  textStyle="ds.heading.primary"
                  color="ds.text.default"
                >
                  <Text textStyle="ds.heading.primary">Full name</Text>
                </FormLabel>
                <Input
                  autoFocus
                  type="text"
                  placeholder="Enter your full name"
                  onKeyDown={(e) =>
                    e.key.match(/[:/.]/) ? e.preventDefault() : e
                  }
                  {...register("fullName", {
                    required: true,
                  })}
                />

                {errors?.fullName && <FieldRequired />}
              </Flex>
            </FormControl>
            <FormControl isInvalid={!!errors?.preferredName}>
              <Flex flexDirection="column">
                <FormLabel
                  textStyle="ds.heading.primary"
                  color="ds.text.default"
                >
                  <Text textStyle="ds.heading.primary">Preferred name</Text>
                </FormLabel>

                <Input
                  type="text"
                  placeholder="Tell us how you'd like to be addressed"
                  {...register("preferredName", { required: true })}
                />

                {errors?.preferredName && <FieldRequired />}
              </Flex>
            </FormControl>
            <FormControl isInvalid={!!errors?.country}>
              <Flex flexDirection="column">
                <FormLabel
                  textStyle="ds.heading.primary"
                  color="ds.text.default"
                >
                  <Text textStyle="ds.heading.primary">Country</Text>
                </FormLabel>

                <CountrySelect {...register("country", { required: true })} />

                {errors?.country && <FieldRequired />}
              </Flex>
            </FormControl>
            <FormControl isInvalid={!!errors?.email}>
              <Flex flexDirection="column">
                <FormLabel
                  textStyle="ds.heading.primary"
                  color="ds.text.default"
                >
                  <Text textStyle="ds.heading.primary">Email</Text>
                </FormLabel>

                <Input
                  type="email"
                  placeholder="name@email.com"
                  {...register("email", {
                    required: true,
                    validate: (val) =>
                      isValidEmail(val) || "Invalid email address",
                  })}
                />

                {errors?.email && (
                  <>
                    {errors.email.type === "required" && <FieldRequired />}
                    {errors.email.type === "validate" && (
                      <FormErrorMessage color="ds.text.danger">
                        {errors.email.message}
                      </FormErrorMessage>
                    )}
                  </>
                )}
              </Flex>
            </FormControl>
            <FormControl isInvalid={!!errors?.password}>
              <Flex flexDirection="column">
                <FormLabel
                  textStyle="ds.heading.primary"
                  color="ds.text.default"
                >
                  <Text textStyle="ds.heading.primary">Password</Text>
                </FormLabel>

                <Input
                  type="password"
                  placeholder="8+ characters"
                  {...register("password", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                    },
                    validate: (val) =>
                      /(?=.*[a-z])(?=.*[A-Z0-9\W])/.test(val) ||
                      "Password must have at least one lowercase letter and one uppercase letter/digit/special character",
                  })}
                />

                {(errors?.password?.type === "validate" ||
                  errors?.password?.type === "minLength") && (
                  <FormErrorMessage color="ds.text.danger">
                    {errors.password.message}
                  </FormErrorMessage>
                )}

                {errors?.password?.type === "required" && <FieldRequired />}
              </Flex>
            </FormControl>
            <FormControl isInvalid={!!errors.acceptTerms}>
              <Checkbox
                {...register("acceptTerms", { required: true })}
                data-qa="accept_terms"
                alignItems="flex-start"
                sx={{
                  "& > .chakra-checkbox__control": {
                    marginTop: "2px",
                  },
                }}
              >
                <Text textStyle="ds.paragraph.primary" color="ds.text.default">
                  I accept UserCrowd{"\u2019"}s{" "}
                  <Link
                    href={Constants.PRIVACY_POLICY_URL}
                    isExternal
                    colorScheme="brand.primary"
                    fontWeight={400}
                    sx={{ "&:not(:hover)": { textDecoration: "none" } }}
                  >
                    privacy policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    href={ROUTES.LEGAL.TERMS.path}
                    isExternal
                    colorScheme="brand.primary"
                    fontWeight={400}
                    sx={{ "&:not(:hover)": { textDecoration: "none" } }}
                  >
                    terms and conditions
                  </Link>
                </Text>
              </Checkbox>
            </FormControl>

            {recaptchaSiteKey !== null && (
              <FormControl>
                <Flex basis="full" justify="center" mt={1}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    onErrored={() => resetRecaptchaResponse()}
                    onExpired={() => resetRecaptchaResponse()}
                    onChange={setRecaptchaResponse}
                    sitekey={recaptchaSiteKey}
                  />
                </Flex>
              </FormControl>
            )}

            <Button
              variant="primary"
              size="emphasized"
              type="submit"
              isLoading={isLoading}
            >
              Apply now
            </Button>
          </Flex>
        </form>
      </Flex>

      <Text
        textAlign="center"
        mr={1}
        color="ds.text.default"
        textStyle="ds.paragraph.primary"
      >
        Already have an account?{" "}
        <Link
          as={ReactRouterLink}
          to={ROUTES.USERS.SIGN_IN.path}
          colorScheme="brand.primary"
          sx={{ "&:not(:hover)": { textDecoration: "none" } }}
        >
          Log in
        </Link>
      </Text>
    </>
  )
}

const FieldRequired = () => {
  return (
    <FormErrorMessage>
      <AlertCircleFilledIcon boxSize={4} color="ds.icons.danger" me={1.5} />
      <Text color="ds.text.danger">This field is required</Text>
    </FormErrorMessage>
  )
}
