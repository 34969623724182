import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from "@chakra-ui/react"
import { Button, Heading, Tag } from "DesignSystem/components"
import { tokens } from "DesignSystem/tokens"
import { UserProfileFilledIcon } from "Icons/UserProfileFilledIcon"
import { ROUTES } from "UserCrowd/views/routes"
import { formatPercentage } from "Utilities/number"
import React from "react"
import { Link } from "react-router-dom"
import { usePanelistStats } from "~/api/generated/usabilityhub-components"

export const ProfileStrengthSection = () => {
  const { data: statsData, isLoading, isError } = usePanelistStats({})

  if (isLoading || isError) {
    return null
  }

  const profileStrength = statsData.profile_completeness
  const isComplete = profileStrength >= 100

  const progressColor = isComplete
    ? "ds.icon.success"
    : profileStrength >= 50
      ? "ds.icon.warning"
      : "ds.icon.danger"

  return (
    <Flex direction="column" gap={4}>
      <Flex justify="space-between">
        <Tag label="Profile" leftIcon={UserProfileFilledIcon} />
        <Button
          as={Link}
          to={ROUTES.SETTINGS.PROFILE.path}
          variant="secondary"
          size="compact"
        >
          {isComplete ? "Update profile" : "Complete profile"}
        </Button>
      </Flex>

      <Flex align="center" gap={4}>
        {/* Wrap the progress in a fixed-width box to clip the gap between the circle and the viewbox edges */}
        <Box w="64px" h="64px">
          <CircularProgress
            capIsRound
            // Chakra adds a gap between the circle and the viewbox edges, so we need this size if we want a 64px circle
            size="71px"
            // Design says 6px thickness, but since Chakra is not applying `vector-effect: non-scaling-stroke` here,
            // the resulting stroke-width is relative to the viewbox. If you apply `vector-effect: non-scaling-stroke`
            // using the sx prop, it rotates the progress highlight stroke's starting point.
            // I just adjusted the size here until it looked right visually instead.
            thickness="8px"
            ml="-3.5px"
            mt="-3.5px"
            color={progressColor}
            value={profileStrength}
          >
            <CircularProgressLabel
              textStyle="ds.interface.medium"
              // Chakra bakes in a font size into this component that the textStyle does not override,
              // so we need to manually set the font size
              fontSize={tokens.textStyles.ds.interface.medium.fontSize}
            >
              {formatPercentage(profileStrength)}
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
        <Flex direction="column" gap={2}>
          <Heading as="h2" textStyle="ds.display.secondary">
            Profile strength
          </Heading>
          <Text textStyle="ds.paragraph.primary">
            Answer more profile questions to increase your chances of being
            chosen for studies.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
