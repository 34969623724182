import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TestCompleteFilledIcon = createIcon({
  displayName: "TestCompleteFilledIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="complete-circle">
      <path
        id="Combined Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8C1 4.136 4.136 1 8 1C11.864 1 15 4.136 15 8C15 11.864 11.864 15 8 15C4.136 15 1 11.864 1 8ZM10.2812 5.53235L6.94254 9.30983L5.63533 8.20058C5.29516 7.91191 4.77607 7.93767 4.46892 8.25945C4.15384 8.58954 4.18253 9.10342 4.53136 9.39943L6.47581 11.0494C6.82518 11.3459 7.36098 11.3096 7.66324 10.9677L11.5521 6.56767C11.854 6.22612 11.8046 5.71379 11.4446 5.43091C11.0934 5.15495 10.5756 5.19934 10.2812 5.53235Z"
        fill="currentColor"
      />
    </g>
  ),
})
