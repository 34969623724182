import { Link, Text, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import { ModeratedStudyTag } from "Shared/components/StudyTypeTags"
import { deviceTypesToPermittedDeviceType } from "Types"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useCallback } from "react"
import { useNavigate } from "react-router"
import {
  ListUsercrowdOrderAssignmentsResponse,
  useAcceptModeratedStudyOrderAssignment,
  useCurrentPanelistInfo,
  useRejectModeratedStudyOrderAssignment,
} from "~/api/generated/usabilityhub-components"
import { AssignmentCard } from "../AssignmentCard/AssignmentCard"
import { AssignmentCardStudyDetailTags } from "../AssignmentCard/AssignmentCardStudyDetailTags"

type Props = {
  assignment: ListUsercrowdOrderAssignmentsResponse["moderated_study_order_assignments"][0]
}

export const ModeratedStudyCard: React.FC<Props> = ({ assignment }) => {
  const { data: panelistInfo } = useCurrentPanelistInfo({})
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()

  const { mutate: acceptAssignment } = useAcceptModeratedStudyOrderAssignment({
    onSuccess: (data) => {
      void queryClient.invalidateQueries(["api", "order_assignments"])
      navigate(
        ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.WELCOME.buildPath({
          moderatedStudyApplicationId: data.moderated_study_application_id,
        })
      )
    },
    onError: () => {
      void queryClient.invalidateQueries(["api", "order_assignments"])
      toast({
        title: "Interview no longer available",
        duration: null,
        status: "error",
      })
    },
  })
  const { mutate: rejectAssignment } = useRejectModeratedStudyOrderAssignment({
    onSuccess: () => {
      return queryClient.invalidateQueries(["api", "order_assignments"])
    },
  })

  const handleAccept = useCallback(() => {
    acceptAssignment({
      pathParams: { id: assignment.id },
    })
  }, [acceptAssignment, assignment.id])

  const handleReject = useCallback(() => {
    rejectAssignment({
      pathParams: { id: assignment.id },
    })
  }, [rejectAssignment, assignment.id])

  const permittedDeviceType = deviceTypesToPermittedDeviceType(
    assignment.device_requirement.device_types
  )

  return (
    <AssignmentCard
      studyTypeTag={<ModeratedStudyTag />}
      incentiveText={assignment.incentive_text}
      tags={
        <AssignmentCardStudyDetailTags
          hasScreener={assignment.has_screener}
          recordingTypes={assignment.device_requirement.device_peripherals}
          permittedDeviceType={permittedDeviceType}
        />
      }
      estimatedLengthText={`${assignment.duration} minute session`}
      participateAction={handleAccept}
      participateLabel="Book a session"
      rejectAction={handleReject}
      permittedDeviceType={permittedDeviceType}
      currentDeviceType={panelistInfo?.current_device_type ?? "desktop"}
    >
      <Text textStyle="ds.paragraph.primary">
        {getInviteText(assignment)}{" "}
        <Link
          variant="noUnderline"
          whiteSpace="nowrap"
          href={Constants.PANELIST_INTERVIEWS_FAQ_URL}
          rel="noopener noreferer"
          target="_blank"
        >
          Learn more
        </Link>
      </Text>
    </AssignmentCard>
  )
}

const getInviteText = ({
  has_screener,
  is_handpick,
}: Pick<Props["assignment"], "has_screener" | "is_handpick">) => {
  if (is_handpick) {
    return has_screener
      ? "If eligible, you will receive an invitation to book a time for an online meeting."
      : "You will be asked to apply for an online meeting."
  } else {
    return has_screener
      ? "If eligible, you will be asked to book a time for an online meeting."
      : "You will be asked to book a time for an online meeting."
  }
}
