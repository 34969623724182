import { Text } from "@chakra-ui/react"
import { PracticeTestIcon } from "Icons/PracticeTestIcon"
import { UsabilityTestTag } from "Shared/components/StudyTypeTags"
import { AssignmentCard } from "UserCrowd/components/AssignmentCard/AssignmentCard"
import { AssignmentCardStudyDetailTags } from "UserCrowd/components/AssignmentCard/AssignmentCardStudyDetailTags"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"

export const PracticeTestCard: React.FC = () => {
  return (
    <AssignmentCard
      studyTypeTag={<UsabilityTestTag />}
      estimatedLengthText="About 1 minute"
      incentiveIcon={<PracticeTestIcon boxSize={5} color="ds.icon.subtle" />}
      incentiveText="Practice"
      tags={<AssignmentCardStudyDetailTags permittedDeviceType="any" />}
      participateAction={ROUTES.DASHBOARD.START_PRACTICE_TEST.path}
      participateLabel="Take now"
    >
      <Text textStyle="ds.paragraph.primary" color="ds.text.default">
        Practice tests help you get familiar with our platform and the types of
        questions you’ll encounter. Completion is mandatory before you can
        receive real studies, and your response will be reviewed by the
        UserCrowd Team.
      </Text>

      <Text textStyle="ds.paragraph.secondary" color="ds.text.default">
        Note: There is no financial compensation for completing practice tests.
      </Text>
    </AssignmentCard>
  )
}
