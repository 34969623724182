import { Flex, Text } from "@chakra-ui/react"
import { Badge, Heading } from "DesignSystem/components"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { CheckCircleIcon } from "Icons/CheckCircleIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import { TimeFilledIcon } from "Icons/TimeFilledIcon"
import { assertNever } from "UsabilityHub/components/FilterControls/utils"
import React from "react"

type StepCardProps = {
  status: "active" | "pending" | "complete" | "rejected"
  title: string
  description?: React.ReactNode
  action?: React.ReactNode
  showBadge?: boolean
}

export const OnboardingDrawerStepCard: React.FC<StepCardProps> = ({
  status,
  action,
  description,
  title,
  showBadge = false,
}) => {
  return (
    <Flex
      gap={2}
      bg="ds.surface.raised.resting"
      boxShadow="ds.raised"
      py={4}
      ps={3}
      pe={4}
      rounded="12px"
    >
      <OnboardingDrawerStepIcon status={status} />

      <Flex flexGrow={1} flexDirection="column" gap={4} alignItems="flex-start">
        <Flex flexDirection="column" gap={2} w="full">
          <Flex gap={2} align="baseline" justify="space-between">
            <Heading as="h3" textStyle="ds.heading.secondary">
              {title}
            </Heading>
            {showBadge && <OnboardingDrawerBadge status={status} />}
          </Flex>
          {description && (
            <Text textStyle="ds.paragraph.secondary">{description}</Text>
          )}
        </Flex>
        {status === "active" && action}
      </Flex>
    </Flex>
  )
}

const OnboardingDrawerStepIcon: React.FC<{
  status: "active" | "pending" | "complete" | "rejected"
}> = ({ status }) => {
  if (status === "active")
    return <CheckCircleIcon boxSize={5} color="ds.icon.disabled" />

  if (status === "pending")
    return <TimeFilledIcon boxSize={5} color="ds.icon.subtle" />

  if (status === "complete")
    return <CheckCircleFilledIcon boxSize={5} color="ds.icon.success" />

  if (status === "rejected")
    return <RemoveFilledIcon boxSize={5} color="ds.icon.danger" />

  assertNever(status)
}

const OnboardingDrawerBadge: React.FC<{
  status: "active" | "pending" | "complete" | "rejected"
}> = ({ status }) => {
  if (status === "active") return <Badge label="Required" />
  if (status === "pending") return <Badge label="Pending" />
  if (status === "complete") return null
  if (status === "rejected") return <Badge label="Rejected" />

  assertNever(status)
}
