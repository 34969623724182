import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CircularProgress,
  HStack,
  Icon,
  ScaleFade,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react"
import { JsForm } from "Components/form/form"
import { useCountries } from "Hooks/use-countries"
import { useLanguages } from "Hooks/use-languages"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { Card, CardBody, LegacyCardHeader } from "Shared/components/Card/Card"
import { DemographicAttributeForPanelist, Fluency } from "Types"
import { CountryField } from "UserCrowd/components/CountryField/CountryField"
import { DemographicAttributeField } from "UserCrowd/components/DemographicAttributeField"
import { LanguagesField } from "UserCrowd/components/LanguagesField/LanguagesField"
import { SettingsPage } from "UserCrowd/components/SettingsPage/SettingsPage"
import { YearOfBirthField } from "UserCrowd/components/YearOfBirthField"
import { useDemographicProfileData } from "UserCrowd/hooks/useDemographicProfileData"
import { setUserCrowdDocumentTitle } from "Utilities/set-user-crowd-document-title"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"

interface DemographicAttributeGroupContainerProps {
  name: string
  demographicAttributes: DemographicAttributeForPanelist[]
  allInitialSelectedOptions: number[]
  customFieldsCount: number
  customFieldsAnsweredCount: number
}

const DemographicAttributeGroupContainer: React.FC<
  React.PropsWithChildren<DemographicAttributeGroupContainerProps>
> = ({
  name,
  demographicAttributes,
  allInitialSelectedOptions,
  customFieldsCount,
  customFieldsAnsweredCount,
  children,
}) => {
  const [answered, setAnswered] = useState<number[]>(
    demographicAttributes
      .filter((da) =>
        da.options.find((o) => allInitialSelectedOptions.includes(o.id))
      )
      .map(({ id }) => id)
  )

  const questionCount = demographicAttributes.length + customFieldsCount
  const answeredQuestionCount = answered.length + customFieldsAnsweredCount
  const answeredQuestionPercentage =
    (answeredQuestionCount / questionCount) * 100

  return (
    <AccordionItem
      px={0}
      py={5}
      _first={{ borderTopWidth: "0" }}
      _last={{ borderBottomWidth: "0" }}
    >
      <Box px={4}>
        <AccordionButton
          _hover={{ backgroundColor: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <Box flex="1" textAlign="left">
            <Text fontWeight="bold" fontSize="lg">
              {name}
            </Text>
          </Box>
          <HStack>
            <Text fontWeight="medium" fontSize="sm" color="gray.500">
              {`${answeredQuestionCount}/${questionCount} questions answered`}
            </Text>
            <Box pr={1} position="relative" boxSize={5}>
              <CircularProgress
                value={answeredQuestionPercentage}
                boxSize={5}
                size={5}
                thickness={16}
                color="green.500"
                display="block"
              />
              <Box boxSize={5} inset={0} position="absolute">
                <ScaleFade
                  initialScale={0}
                  in={answeredQuestionPercentage === 100}
                  style={{ height: "20px" }}
                >
                  <Icon
                    display="block"
                    as={CheckCircleFilledIcon}
                    color="green.500"
                    transform="translate(-2.5px, -2.5px)"
                    w="25px"
                    h="25px"
                  />
                </ScaleFade>
              </Box>
            </Box>
            <AccordionIcon />
          </HStack>
        </AccordionButton>
      </Box>
      <AccordionPanel>
        <VStack px={4} spacing={6}>
          {children}
          {demographicAttributes.map(
            ({
              id,
              code,
              question,
              options,
              multi_select,
              profile_helper,
              required,
            }) => {
              return (
                <DemographicAttributeField
                  id={code}
                  key={id}
                  label={question}
                  helpText={profile_helper}
                  required={required}
                  isMulti={multi_select}
                  options={options}
                  demographicAttributeId={id}
                  allInitialSelectedOptions={allInitialSelectedOptions}
                  markAnswered={(demographicAttributeId) => {
                    if (!answered.includes(demographicAttributeId)) {
                      setAnswered((ids) => [...ids, demographicAttributeId])
                    }
                  }}
                  markUnanswered={(demographicAttributeId) => {
                    if (answered.includes(demographicAttributeId)) {
                      setAnswered((ids) =>
                        ids.filter((id) => id !== demographicAttributeId)
                      )
                    }
                  }}
                />
              )
            }
          )}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

interface CustomGeneralFieldsProps {
  formattedAddress: string | null
  googlePlaceId: string | null
  fluencies: Fluency[]
  yearOfBirth: number | null
  setCustomAnsweredFieldsCount: Dispatch<SetStateAction<number>>
  isAdmin: boolean
}

const CustomGeneralFields: React.FC<
  React.PropsWithChildren<CustomGeneralFieldsProps>
> = ({
  formattedAddress,
  googlePlaceId,
  fluencies,
  yearOfBirth,
  setCustomAnsweredFieldsCount,
  isAdmin,
}) => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})
  const testerId = currentPanelist?.id

  const { isLoading: areCountriesLoading } = useCountries()
  const languages = useLanguages()

  const [isCountryAnswered, setIsCountryAnswered] = useState(!!googlePlaceId)
  const [isLanguageAnswered, setIsLanguageAnswered] = useState(
    fluencies.filter((fluency: Fluency) => fluency.is_fluent).length > 0
  )
  const [isYearOfBirthAnswered, setIsYearOfBirthAnswered] = useState(
    yearOfBirth !== null
  )

  useEffect(() => {
    setCustomAnsweredFieldsCount(
      Number(isCountryAnswered) +
        Number(isLanguageAnswered) +
        Number(isYearOfBirthAnswered)
    )
  }, [
    setCustomAnsweredFieldsCount,
    isCountryAnswered,
    isLanguageAnswered,
    isYearOfBirthAnswered,
  ])

  return (
    <>
      {areCountriesLoading ? null : (
        <CountryField
          initialPlaceId={googlePlaceId}
          initialFormattedAddress={formattedAddress}
          setIsCountryAnswered={setIsCountryAnswered}
          shouldResetPhoneVerification
        />
      )}
      {testerId && languages.length > 0 && (
        <LanguagesField
          testerId={testerId}
          initialFluencies={fluencies}
          setIsLanguageAnswered={setIsLanguageAnswered}
          isAdmin={isAdmin}
        />
      )}
      <YearOfBirthField
        initialYearOfBirth={yearOfBirth}
        setIsYearOfBirthAnswered={setIsYearOfBirthAnswered}
      />
    </>
  )
}

interface TestersProfileProps {
  isAdmin?: boolean
}

export const ProfileSettingsRoute: React.FC<
  React.PropsWithChildren<TestersProfileProps>
> = ({ isAdmin = false }) => {
  const { data } = useDemographicProfileData()

  useEffect(() => {
    setUserCrowdDocumentTitle("Your tester profile")
  }, [])

  // There are 3 custom fields in CustomGeneralFields
  const customFieldsCount = 3
  const [customFieldsAnsweredCount, setCustomAnsweredFieldsCount] = useState(0)

  return (
    <SettingsPage>
      <Card>
        <LegacyCardHeader size="lg">Your tester profile</LegacyCardHeader>
        {data ? (
          <JsForm>
            <CardBody p={0}>
              <Accordion allowMultiple defaultIndex={[0]}>
                {data.demographicAttributeGroups.map(
                  ({ group_name, demographic_attributes }) => {
                    if (group_name === "General") {
                      return (
                        <DemographicAttributeGroupContainer
                          key={group_name}
                          name={group_name}
                          demographicAttributes={demographic_attributes}
                          allInitialSelectedOptions={
                            data.allInitialSelectedOptions
                          }
                          customFieldsCount={customFieldsCount}
                          customFieldsAnsweredCount={customFieldsAnsweredCount}
                        >
                          <CustomGeneralFields
                            formattedAddress={data.formattedAddress}
                            googlePlaceId={data.googlePlaceId}
                            fluencies={data.fluencies}
                            yearOfBirth={data.yearOfBirth}
                            setCustomAnsweredFieldsCount={
                              setCustomAnsweredFieldsCount
                            }
                            isAdmin={isAdmin}
                          />
                        </DemographicAttributeGroupContainer>
                      )
                    } else {
                      return (
                        <DemographicAttributeGroupContainer
                          key={group_name}
                          name={group_name}
                          demographicAttributes={demographic_attributes}
                          allInitialSelectedOptions={
                            data.allInitialSelectedOptions
                          }
                          customFieldsCount={0}
                          customFieldsAnsweredCount={0}
                        />
                      )
                    }
                  }
                )}
              </Accordion>
            </CardBody>
          </JsForm>
        ) : (
          <CardBody>
            <HStack>
              <Spinner size="xs" />
              <Text>Loading&hellip;</Text>
            </HStack>
          </CardBody>
        )}
      </Card>
    </SettingsPage>
  )
}
