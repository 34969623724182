import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RecordThinkAloudFilledIcon = createIcon({
  displayName: "RecordThinkAloudFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.183 14h4.642c1.09 0 1.835 0 2.473.17a5 5 0 0 1 3.536 3.536c.23.86.163 1.778.17 2.658.001.107.003.323-.051.524a1.5 1.5 0 0 1-1.06 1.06c-.202.055-.418.053-.525.052A860.571 860.571 0 0 0 3.64 22c-.107.001-.323.003-.524-.051a1.5 1.5 0 0 1-1.061-1.06c-.054-.202-.052-.418-.051-.525.007-.878-.06-1.8.17-2.658A5 5 0 0 1 5.71 14.17C6.348 14 7.092 14 8.183 14ZM5.504 7a5 5 0 1 1 10 0 5 5 0 0 1-10 0ZM20.39 1.111a1 1 0 0 1 1.347.431C22.539 3.1 23 4.985 23 7s-.46 3.9-1.263 5.458a1 1 0 1 1-1.778-.916C20.607 10.285 21 8.717 21 7c0-1.717-.393-3.285-1.041-4.542a1 1 0 0 1 .43-1.347Zm-2.837 2.137a1 1 0 0 1 1.341.448c.479.957.748 2.096.748 3.304 0 1.208-.27 2.348-.747 3.304a1 1 0 1 1-1.79-.894c.332-.663.537-1.493.537-2.41 0-.916-.205-1.747-.537-2.41a1 1 0 0 1 .448-1.341Z"
      fill="currentColor"
    />
  ),
})
