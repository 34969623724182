import { Flex, FlexProps, Text } from "@chakra-ui/react"
import React from "react"

type ModalProgress = {
  currentPageIndex: number
  totalPageCount: number
}

type Props = {
  progress: ModalProgress
}

export const OnboardingFlowProgress: React.FC<FlexProps & Props> = ({
  progress,
  ...flexProps
}) => (
  <Flex
    textStyle="ds.paragraph.emphasized"
    color="ds.text.subtlest"
    gap={1}
    {...flexProps}
    sx={{
      fontVariantNumeric: "tabular-nums",
    }}
  >
    <Text color="ds.text.default">{progress.currentPageIndex + 1}</Text>
    <Text>/</Text>
    <Text>{progress.totalPageCount}</Text>
  </Flex>
)
