import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { SettingsCogIcon } from "Icons/SettingsCogIcon"
import { useSignout } from "Shared/hooks/useSignout"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

type Props = {
  isOnboarding?: boolean
}

export const SettingsMenu: React.FC<Props> = ({ isOnboarding = false }) => {
  const signout = useSignout()

  return (
    <Box textColor="text.primary">
      <Menu isLazy>
        <MenuButton
          as={Button}
          variant="secondary"
          size={["compact", "default"]}
          aria-label="Settings"
          data-qa="settings-menu-button"
          rightIcon={<ChevronDownIcon boxSize={4} />}
          leftIcon={<SettingsCogIcon />}
        >
          Settings
        </MenuButton>
        <Portal>
          <MenuList textColor="text.primary" zIndex={2}>
            <MenuItem
              as={RouterLink}
              to={ROUTES.SETTINGS.ACCOUNT.path}
              isDisabled={isOnboarding}
            >
              Account settings
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to={ROUTES.SETTINGS.NOTIFICATIONS.path}
              isDisabled={isOnboarding}
            >
              Availability & notifications
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to={ROUTES.SETTINGS.PROFILE.path}
              isDisabled={isOnboarding}
            >
              Profile
            </MenuItem>
            <MenuItem onClick={signout}>Logout</MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}
