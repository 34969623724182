import { Box, Flex, Link, Spinner, Text, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { JsForm } from "Components/form/form"
import { UhPhoneInput, prependPlus } from "Components/form/phone-input"
import { Alert, Button, Heading, Input } from "DesignSystem/components"
import React, { ChangeEvent, useState } from "react"
import "react-phone-number-input/style.css"
import { stripWhitespace } from "Utilities/string"
import { isBlank } from "Utilities/values"
import {
  useCurrentPanelistInfo,
  usePhoneVerificationRequestCode,
  usePhoneVerificationVerify,
} from "~/api/generated/usabilityhub-components"

const VerificationCodeLength = 4

type Props = {
  initialPhoneNumber: string | null
}

const TestersPhoneFormImpl: React.FC<Props> = ({ initialPhoneNumber }) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const [phone, setPhone] = useState(initialPhoneNumber ?? "")
  const [code, setCode] = useState("")
  const [hasCodeBeenSent, setHasCodeBeenSent] = useState(false)

  const { mutate: requestCode, isLoading: isRequestingCode } =
    usePhoneVerificationRequestCode({
      onSuccess: () => {
        toast({ status: "success", title: "Verification code sent" })
        setHasCodeBeenSent(true)
      },
      onError: (error) => {
        toast({
          status: "error",
          title: error.payload.message ?? "Sending verification code failed",
        })
      },
    })

  const { mutate: verifyCode, isLoading: isSubmittingCode } =
    usePhoneVerificationVerify({
      onSuccess: () => {
        queryClient.invalidateQueries(["api", "usercrowd", "panelist", "me"], {
          exact: true,
        })
        toast({ status: "success", title: "Phone number verified" })
      },
      onError: (error) => {
        toast({
          status: "error",
          title: error.payload.message ?? "Something went wrong",
        })
      },
    })

  // `phone` is sometimes `undefined`.
  const handlePhoneChange = (phone = "") => {
    setPhone(phone)
    setHasCodeBeenSent(false)
  }

  const handleRequestCode = () => {
    requestCode({
      body: {
        phone_number: phone,
      },
    })
  }

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(stripWhitespace(event.target.value))
  }

  const handleSubmitCode = () => {
    verifyCode({
      body: {
        phone_number: phone,
        code: code,
      },
    })
  }

  return (
    <>
      <Heading
        as="h2"
        color="ds.text.default"
        textStyle="ds.display.primary"
        textAlign="center"
      >
        {hasCodeBeenSent
          ? "Verify your phone number"
          : "Enter your phone number"}
      </Heading>

      <Flex
        direction="column"
        w="full"
        maxW="500px"
        rounded="16px"
        bg="ds.surface.raised.resting"
        boxShadow="ds.raised"
        p={6}
      >
        {hasCodeBeenSent ? (
          <JsForm onSubmit={handleSubmitCode}>
            <Flex direction="column" gap={6} my={4}>
              <Text>Enter the verification code we sent to {phone}</Text>

              <Box maxW="126px">
                <Input
                  value={code}
                  minLength={VerificationCodeLength}
                  maxLength={VerificationCodeLength}
                  onChange={handleCodeChange}
                  placeholder="Four-digit code"
                />
              </Box>

              <Button
                variant="primary"
                size="emphasized"
                type="submit"
                isLoading={isSubmittingCode}
              >
                Verify phone number
              </Button>

              <Alert
                status="info"
                description={
                  <>
                    If you don’t receive a SMS containing a verification code,
                    please{" "}
                    <Link
                      variant="link"
                      cursor="pointer"
                      onClick={() => setHasCodeBeenSent(false)}
                    >
                      check your number
                    </Link>{" "}
                    or{" "}
                    <Link
                      variant="link"
                      cursor="pointer"
                      onClick={handleRequestCode}
                    >
                      resend SMS
                    </Link>
                    .
                  </>
                }
              />
            </Flex>
          </JsForm>
        ) : (
          <JsForm onSubmit={handleRequestCode}>
            <Flex flexDirection="column" align="stretch" gap={6}>
              <Text color="ds.text.default" textStyle="ds.paragraph.emphasized">
                Include the country code. VoIP numbers are not permitted.
              </Text>

              <UhPhoneInput
                id="phone-number"
                w="full"
                onChange={handlePhoneChange}
                placeholder="Mobile phone number"
                value={prependPlus(phone)}
              />

              <Button
                type="submit"
                variant="primary"
                size="emphasized"
                w="full"
                isDisabled={isBlank(phone) || hasCodeBeenSent}
                isLoading={isRequestingCode}
              >
                Send verification code SMS
              </Button>
            </Flex>
          </JsForm>
        )}
      </Flex>
    </>
  )
}

export const TestersPhoneForm: React.FC = () => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})

  if (!currentPanelist) return <Spinner />

  return (
    <TestersPhoneFormImpl initialPhoneNumber={currentPanelist.phone_number} />
  )
}
