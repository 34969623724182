import { Flex, Grid, Image, UseDisclosureReturn } from "@chakra-ui/react"
import { Button, IconButton } from "DesignSystem/components"
import { ChevronLeftIcon } from "Icons/ChevronLeftIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { DisplayModal } from "Shared/components/DisplayModal/DisplayModal"
import React, { useEffect, useState } from "react"
import { OnboardingFlowProgress } from "./OnboardingFlowProgress"

export type OnboardingFlowPage = {
  content: React.ReactNode
  imageSrc: string
  nextButtonLabel?: string
  previousButtonLabel?: string
}

type Props = {
  pages: OnboardingFlowPage[]
  onFinish?: () => void
  onCancel?: () => void
} & UseDisclosureReturn

type PageState = {
  idx: number
  // The direction state is left over from a previous implementation that used
  // animation to transition between pages - leaving it in place in case we want
  // to add animation back later.
  direction: "next" | "previous"
}
const INITIAL_PAGE_STATE = {
  idx: 0,
  direction: "next",
} as const

/**
 * Simple onboarding slideshow/wizard component.
 *
 * It accepts:
 * - a `pages` array containing the content and optional text overrides
 * for the next/previous buttons
 * - the return value from Chakra's `useDisclosure` hook
 * - an optional `onFinish` function that runs when the onboarding is
 * completed (as opposed to `onCancel` which is called if they escape out of it
 * or click outside it)
 *
 * If you need a custom `onClose` function, that can be passed to the
 * `useDisclosure` hook in the consumer.
 *
 * @example
 * const ExamplePage: OnboardingFlowIndividualPage = {
 *   content: (
 *     <Flex flexDirection="column" gap={4}>
 *       <Heading as="h3" textStyle="ds.display.primary">
 *         This is the heading text
 *       </Heading>
 *       <Text textStyle="ds.paragraph.emphasized">
 *         This is the body text
 *       </Text>
 *     </Flex>
 *   ),
 *   imageSrc: SomeImportedImageSrc,
 *   nextButtonLabel: "Next"
 * }
 *
 * // You can extract { isOpen, onOpen, onClose } here to control
 * // the onboarding from the consumer
 * const onboardingDisclosure = useDisclosure()
 *
 * // Render:
 * <OnboardingFlow
 *   {...onboardingDisclosure}
 *   pages={[ExamplePage]}
 *   onFinish={() => {console.log("done!")}}
 *   onCancel={() => {console.log("onboarding canceled!")}}
 * />
 *
 * // Note: in your implementation, you may want to preload the images
 * // used in each page so that they work smoothly, for example:
 * <Helmet>
 *   <link rel="preload" href={slide1} as="image" />
 *   <link rel="preload" href={slide2} as="image" />
 *   <link rel="preload" href={slide3} as="image" />
 *   <link rel="preload" href={slide4} as="image" />
 * </Helmet>
 */
// ts-prune-ignore-next
export const OnboardingFlow: React.FC<Props> = ({
  pages,
  isOpen,
  onClose,
  onFinish,
  onCancel,
}) => {
  const [currentPageState, setCurrentPageState] =
    useState<PageState>(INITIAL_PAGE_STATE)
  const currentPage = pages[currentPageState.idx]

  useEffect(() => {
    return () => setCurrentPageState(INITIAL_PAGE_STATE)
  }, [isOpen])

  const isFirstPage = currentPageState.idx === 0
  const isLastPage = currentPageState.idx === pages.length - 1

  const handleNext = () => {
    if (isLastPage) {
      onFinish?.()
    } else {
      changePage("next")
    }
  }

  const handleCancel = () => {
    setCurrentPageState(INITIAL_PAGE_STATE)
    onCancel?.()
    onClose()
  }

  const changePage = (direction: "next" | "previous") => {
    setCurrentPageState({
      direction,
      idx: newPageIndex({
        pages,
        currentPageIdx: currentPageState.idx,
        direction,
      }),
    })
  }

  return (
    <DisplayModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick
      onOverlayClick={handleCancel}
    >
      <DisplayModal.TwoPaneContent
        content={
          <Flex
            h="100%"
            w="100%"
            bgColor="ds.background.brand.subtle.resting"
            borderRadius={["none", "16px"]}
            justifyContent="center"
            alignItems="center"
          >
            <Image src={currentPage.imageSrc} objectFit="contain" maxH="100%" />
          </Flex>
        }
        controls={
          <Grid
            gridTemplateColumns={["1fr 1fr 1fr", null, "1fr 1fr"]}
            gap={4}
            width="100%"
            alignItems="center"
          >
            {isFirstPage ? (
              // Just to take up an element for the grid
              <Flex />
            ) : (
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={() => changePage("previous")}
                aria-label="Back"
              />
            )}
            <OnboardingFlowProgress
              display={["flex", null, "none"]}
              justifySelf="center"
              progress={{
                currentPageIndex: currentPageState.idx,
                totalPageCount: pages.length,
              }}
            />
            <Button
              variant="primary"
              rightIcon={isLastPage ? undefined : <ChevronRightIcon />}
              onClick={handleNext}
              justifySelf="end"
            >
              {currentPage.nextButtonLabel}
            </Button>
          </Grid>
        }
      >
        <Grid
          h="full"
          flexGrow="1"
          // 3rem is the height of the DisplayModal controls + content bottom padding above them
          gridTemplateRows={["auto", null, "3rem auto"]}
        >
          <OnboardingFlowProgress
            display={["none", null, "flex"]}
            progress={{
              currentPageIndex: currentPageState.idx,
              totalPageCount: pages.length,
            }}
          />
          <Flex
            flexDirection="column"
            justifyContent={["flex-start", null, "center"]}
          >
            {currentPage.content}
          </Flex>
        </Grid>
      </DisplayModal.TwoPaneContent>
    </DisplayModal>
  )
}

type NewPageIndexProps = {
  pages: OnboardingFlowPage[]
  currentPageIdx: number
  direction: "next" | "previous"
}

const newPageIndex = ({
  pages,
  direction,
  currentPageIdx,
}: NewPageIndexProps): number => {
  const proposed =
    direction === "next" ? currentPageIdx + 1 : currentPageIdx - 1
  if (!Object.keys(pages).includes(String(proposed))) return currentPageIdx
  return proposed
}
