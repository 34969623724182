import { ComponentWithAs, IconProps } from "@chakra-ui/react"
import { DeviceDesktopFilledIcon } from "Icons/DeviceDesktopFilledIcon"
import { DeviceMobileFilledIcon } from "Icons/DeviceMobileFilledIcon"
import { MicrophoneFilledIcon } from "Icons/MicrophoneFilledIcon"
import { RecordScreenFilledIcon } from "Icons/RecordScreenFilledIcon"
import { RecordVideoFilledIcon } from "Icons/RecordVideoFilledIcon"
import { PermittedDeviceType } from "Types"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"

type AssignmentCardStudyDetailTagsContent = {
  recordingTypes: {
    [key in RecordingType]: {
      label: string
      icon?: ComponentWithAs<"svg", IconProps>
    }
  }
  permittedDeviceType: {
    [key in PermittedDeviceType]: {
      label: string
      icon?: ComponentWithAs<"svg", IconProps>
    }
  }
}

export const STUDY_DETAIL_TAGS_CONTENT: AssignmentCardStudyDetailTagsContent = {
  recordingTypes: {
    microphone: {
      label: "Microphone",
      icon: MicrophoneFilledIcon,
    },
    screen: {
      label: "Screen recording",
      icon: RecordScreenFilledIcon,
    },
    camera: {
      label: "Camera",
      icon: RecordVideoFilledIcon,
    },
  },
  permittedDeviceType: {
    any: {
      label: "Any device",
      icon: undefined,
    },
    mobile_only: {
      label: "Mobile",
      icon: DeviceMobileFilledIcon,
    },
    desktop_only: {
      label: "Desktop",
      icon: DeviceDesktopFilledIcon,
    },
  },
} as const
